<template>
  <v-btn
    :color="color"
    :rounded="rounded"
    :text="text"
    :disabled="disabled"
    :x-small="size === 'x-small'"
    :small="size === 'small'"
    :large="size === 'large'"
    :x-large="size === 'x-large'"
    :block="block"
    @click="click"
  >
    <v-progress-circular
      indeterminate
      :color="loadingColor"
      size="25"
      width="2"
      :class="getMargin"
      v-if="loading"
    ></v-progress-circular>
    <div v-if="loading">{{ loadingText }}</div>
    <slot v-else></slot>
  </v-btn>
</template>

<script>
export default {
  props: [
    "color",
    "loading",
    "loadingText",
    "loadingColor",
    "size",
    "disabled",
    "block",
    "text",
    "rounded",
  ],
  computed: {
    getMargin() {
      return this.loadingText && this.loadingText.trim() !== "" ? "mr-3" : "";
    },
  },

  methods: {
    click() {
      if (!this.loading) {
        this.$emit("click");
      }
    },
  },
};
</script>